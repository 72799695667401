import React, { Component } from "react";
import "../../pages/Drives/styles.css";
import {
    Table,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Form,
    FormGroup,
    Label,
    Input
} from "reactstrap";
import api from "../../Services/api.js";
import util from "../../util";
import Header from "../../components/header";
import Breadcrumbs from "../../components/breadcrumbs";
import InputMask from "react-input-mask";
import { Redirect, Link } from "react-router-dom";
import PageTitle from "../../components/pageTitle";
import Sidebar from "../../components/sidebar";
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
import moment from "moment";
import "moment/locale/pt-br";
import ContractsPicker from "../../components/contract_selector";
import InfiniteScroll from 'react-infinite-scroll-component';

import "./styles.css";

import ReactGA from "react-ga";

export default class DrivesMoves extends Component {
    constructor() {
        super();
        this.state = {
            redirect: "",

            // contractId: util.queryParams().contract,
            // contractId: util.queryParams().contract ?
            //     util.queryParams().contract
            //     : "0",
            contractId: util.queryParams().contract ?
                util.queryParams().contract
                : JSON.parse(localStorage.getItem('userContract')).propostaContratoGuid,
            moves: [],
            movesFilter: [],

            loading: false,

            tipoMovimentacao: "",
            dataInicio: moment(new Date()).subtract(1, "year").format("YYYY-MM-DD"),
            dataTermino: moment(new Date()).format("YYYY-MM-DD"),
            equipamento: "",

            current: [],
            prev: -10,
            next: 10,
            hasMore: true
        };
        ReactGA.initialize("UA-173154606-1");
        ReactGA.pageview("/movimentacoesEquipamento");
    }

    contractMoves = () => {
        api.get(
            `/api/contrato/${this.state.contractId}/MovimentacoesEquipamento`
        )
            .then(response => {
                console.log("moves: ", response.data);

                let moves = response.data;

                this.setState({ moves, movesFilter: moves }, this.fetch);
            })
            .catch(err => {
                console.log(err);
            });
    };

    filter = () => {
        const { moves, tipoMovimentacao, dataInicio, dataTermino, equipamento } = this.state;

        // data: "2021-05-10 11:46:45"
        // nmEquipamento: "Escora Super SH Fêmea"
        // nrProposta: "0001/2018-SAO"
        // numero: 24494
        // qtEquipamento: 84
        // tipo: "NF"

        var movesFilter = moves;

        if (tipoMovimentacao) {
            let tipo = tipoMovimentacao == "Retirada" ? "NF" : "ND";
            movesFilter = movesFilter.filter(move => (move.tipo == tipo));

            // let tipo = tipoMovimentacao == "Retirada" ? "RE" : "ND";

            // if (tipoMovimentacao == "Retirada") {
            //     movesFilter = movesFilter.filter(move => (move.tipo == tipo));
            // }
            // else {
            //     movesFilter = movesFilter.filter(move => (move.tipo !== tipo));
            // }
        }

        if (dataInicio) {
            let data = moment(dataInicio);

            movesFilter = movesFilter.filter(move => (moment(move.data.substring(0, 10)).toDate().getTime() >= data.toDate().getTime()));
        }

        if (dataTermino) {
            let data = moment(dataTermino);

            movesFilter = movesFilter.filter(move => (moment(move.data.substring(0, 10)).toDate().getTime() <= data.toDate().getTime()));
        }

        if (equipamento) {
            movesFilter = movesFilter.filter(move => (move.nmEquipamento.toLowerCase().indexOf(equipamento.toLocaleLowerCase()) > -1));
        }

        this.setState({ movesFilter, current: [], prev: -10, next: 10, hasMore: true }, this.fetch);

        console.log(movesFilter);
    }

    fetch = () => {
        const { movesFilter, current, prev, next, hasMore } = this.state;

        console.log('movesFilter: ', movesFilter);
        console.log('current: ', current);
        console.log('prev: ', prev);
        console.log('next: ', next);

        if (current.length === movesFilter.length) {
            let hasMore = false;

            this.setState({ hasMore });

            return;
        }

        let proximaPagina = current.concat(movesFilter.slice(prev + 10, next + 10));

        console.log('proximaPagina: ', proximaPagina);

        this.setState((prevState) => { return { ...prevState, current: proximaPagina, prev: prevState.prev + 10, next: prevState.next + 10, hasMore } });
    }

    componentWillMount = () => {
        api.defaults.headers.common = {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("token")).accessToken
                }`
        };
        this.contractMoves(this.state.contractId);
        if (this.state.contractId == 0)
            document.title = `SH - Histórico de Movimentações`;
    };

    render() {
        return (
            <div className="drivesmoves-container">
                <Header />
                {/* <Breadcrumbs
                    items={[
                        {
                            title: "Home",
                            link: `/home?contract=${this.state.contractId}`
                        },
                        {
                            title: "Movimentações",
                            link: `/moves?contact=${this.state.contractId}`
                        },
                        { title: "Histórico" }
                    ]}
                />
                <ContractsPicker /> */}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                    }}
                >
                    <Sidebar />
                    <div
                        style={{
                            width: "100%"
                        }}
                    >
                        <PageTitle
                            title='Movimentações'
                            imgPath='./icons/sidebar/menu_movimentacao.svg'
                        />
                        <div
                            className="page-body"
                        >
                            <Row>
                                <Col xs={{ size: 12 }} md={{ size: 12, offset: 0 }}>
                                    <Form style={{ width: "100%" }}>
                                        <Row>
                                            <Col xs={12} md={3}>
                                                <FormGroup style={{ marginBottom: 5 }}>
                                                    <Label>Tipo de Movimentação</Label>
                                                    <select
                                                        className="form-control"
                                                        required
                                                        onChange={e => {
                                                            this.setState({
                                                                tipoMovimentacao:
                                                                    e.target.value
                                                            }, this.filter);
                                                        }}
                                                        value={this.state.tipoMovimentacao}
                                                    >
                                                        <option value="">Selecione</option>
                                                        <option value="Retirada">Retirada</option>
                                                        <option value="Devolução">Devolução</option>
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <FormGroup style={{ marginBottom: 5 }}>
                                                    <Label>Data de Início</Label>
                                                    <Input
                                                        type="date"
                                                        name="dataInicio"
                                                        value={this.state.dataInicio}
                                                        placeholder="__/__/____"
                                                        onChange={e => {
                                                            if (
                                                                moment(
                                                                    e.target.value,
                                                                    "YYYY-MM-DD"
                                                                ).format("YYYY-MM-DD") ===
                                                                e.target.value
                                                            ) {
                                                                this.setState({ dataInicio: moment(e.target.value).format("YYYY-MM-DD") }, this.filter);

                                                            }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <FormGroup style={{ marginBottom: 5 }}>
                                                    <Label>Data de Início</Label>
                                                    <Input
                                                        type="date"
                                                        name="dataTermino"
                                                        value={this.state.dataTermino}
                                                        placeholder="__/__/____"
                                                        onChange={e => {
                                                            if (
                                                                moment(
                                                                    e.target.value,
                                                                    "YYYY-MM-DD"
                                                                ).format("YYYY-MM-DD") ===
                                                                e.target.value
                                                            ) {
                                                                this.setState({ dataTermino: moment(e.target.value).format("YYYY-MM-DD") }, this.filter);
                                                            }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <Label>Equipamento</Label>
                                                <Input
                                                    value={this.state.equipamento}
                                                    onChange={e => {

                                                        let equipamento = e.target.value;

                                                        this.setState({
                                                            equipamento
                                                        }, () => {
                                                            if (equipamento.length > 2 || equipamento == "") {
                                                                this.filter()
                                                            }
                                                        });
                                                    }}
                                                    placeholder="Nome do equipamento"
                                                />
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                                <Col
                                    className="drivesmoves-box"
                                    md={{ size: 12, offset: 0 }}
                                    xs={{ size: 12 }}
                                >
                                    <InfiniteScroll
                                        dataLength={this.state.current}
                                        next={this.fetch}
                                        hasMore={this.state.hasMore}
                                        loader={<p style={{ textAlign: "center" }}>Carregando...</p>}
                                        height={350}
                                        initialScrollY={0}
                                    >
                                        <ul>
                                            {
                                                this.state.current.map((value, i) => {
                                                    return (
                                                        <li
                                                            key={i}
                                                            className={value.tipo == "NF" ? "retirada" : "devolucao"}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <path d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zM6 18.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm13.5-9l1.96 2.5H17V9.5h2.5zm-1.5 9c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />
                                                            </svg>
                                                            <span className="data">
                                                                {moment(value.data).format("L")}
                                                            </span>
                                                            <div className="move-details">
                                                                <span className="action">
                                                                    {value.tipo == "NF" ? "Retirada" : "Devolução"}
                                                                </span>
                                                                <br />
                                                                <span className="move">
                                                                    {value.qtEquipamento.toString().replace("-", "")}{" "}
                                                                    {value.nmEquipamento}
                                                                </span>
                                                            </div>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </InfiniteScroll>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
