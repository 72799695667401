import React, { Component } from "react";
import "../../pages/Drives/styles.css";
import {
    Label,
    Input,
    Form,
    FormGroup,
    Row,
    Col,
    Button,
    Table,
    Spinner,
    Container
} from "reactstrap";
import api from "../../Services/api.js";
import _api from "../../Services/_api.js";
import axios from "axios";
import util from "../../util";
import Header from "../../components/header";
import InputMask from "react-input-mask";
import { Redirect, Link } from "react-router-dom";
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
import ContractsPicker from "../../components/contract_selector";
// import Breadcrumbs from "../../components/breadcrumbs";
import moment from "moment";
import "moment/locale/pt-br";
import { button as buttonTheme } from "../../themes";
import PageTitle from "../../components/pageTitle";
import Sidebar from "../../components/sidebar";

import ReactGA from "react-ga";

class Drives extends Component {
    constructor() {
        super();
        this.state = {
            // contractId: util.queryParams().contract,
            // contractId: util.queryParams().contract ?
            //     util.queryParams().contract
            //     : "0",
            contractId: util.queryParams().contract ?
                util.queryParams().contract
                : JSON.parse(localStorage.getItem('userContract')).propostaContratoGuid,
            contractDetails: [],

            action: "",
            cep: "",
            cidade: "",
            rua: "",
            numero: "",
            comp: "",
            data: "",
            total: "R$ 0,00",
            productList: [],
            addressList: [],
            enderecoSH: "",
            tamanhoCaminhao: "",

            redirect: "",
            ceploading: false,
            loading: false,
            selectedContract: null,
            userId: JSON.parse(localStorage.getItem('token')).userId
        };
        ReactGA.initialize("UA-173154606-1");
        ReactGA.pageview("/novaMovimentacao");
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getContractDetails = async id => {
        const promises = [api.get(`/api/contrato/${id}/detalhes`)];
        await Promise.all(promises).then(response => {
            for (let x = 0; x < response.length; x++) {
                let resp = response[x];

                switch (x) {
                    case 0:
                        this.setState({ contractDetails: resp.data[0] });
                        break;
                }
            }
        });
    };

    getContracts = async contractId => {
        api.get(`/api/usuario/${this.state.userId}/contratos`)
            .then(response => {
                let userContracts = response.data;

                // setUserContracts(userContracts);

                const selectedContract = userContracts.find(e => e.idPropostaContrato === parseInt(contractId));

                this.setState({ selectedContract: selectedContract });
            }).catch(error => {
                console.log(error);
            })
    }

    cepInfos = async cep => {
        this.setState({ ceploading: true });
        await axios
            .get(`https://viacep.com.br/ws/${cep}/json/`)
            .then(response => {
                let { logradouro, localidade } = response.data;
                this.setState({ rua: logradouro, cidade: localidade });
            })
            .catch(error => {
                NotificationManager.error(
                    "Não conseguimos localizar seu endereço.",
                    "CEP incorreto",
                    5000
                );
            });
        this.setState({ ceploading: false });
    }

    clearForm() {
        const newProductList = this.state.productList.map(e => {
            e.quantity = '0'
            return e
        })
        this.setState({
            action: '', cep: '', cidade: '', rua: '', numero: '', comp: '',
            enderecoSH: '', data: '', total: 'R$ 0,00',
            productList: newProductList,
            tamanhoCaminhao: ''
        })
    }

    handleSubmit = async e => {
        console.log('iniciando submit');
        e.preventDefault()
        console.log('preventDefault');
        this.setState({ loading: true })
        let {
            action,
            cep,
            cidade,
            rua,
            numero,
            comp,
            data,
            enderecoSH,
            productList,
            tamanhoCaminhao
        } = this.state;
        let productMessage = ``
        // const validProducts = productList.filter(p => p.quantity > 0)
        // validProducts.map(product => {
        //     const isLastProduct = validProducts.indexOf(product) === validProducts.length - 1
        //     productMessage += `${product.equipamento} - ${product.quantity} unidades${isLastProduct ? '.' : ','} `
        // })
        console.log('productMessage');
        console.log(productMessage);

        const selectedUserContract = this.state.selectedContract;
        console.log(selectedUserContract);
        const contractString = selectedUserContract ? `${selectedUserContract.numeroProposta ? selectedUserContract.numeroProposta.toString().padStart(4, "0") : ""}/${selectedUserContract.anoProposta}-${selectedUserContract.nomeUnidade} - ${selectedUserContract.nomeObra}` : "";
        // const actionText = action == 'Retirada'
        //     ? 'Retirar os seguintes equipamentos:'
        //     : 'Devolver os equipamentos previamente retirados'
        const actionText = action == 'Retirada'
            ? `Contrato ${contractString} - Retirar os seguintes equipamentos:`
            : `Contrato ${contractString} - Devolver os equipamentos previamente retirados`
        const fullAddress = `${rua}, ${numero}, ${comp} - ${cidade}, ${cep}`
        const date = moment(data).format("LL")
        const unity = enderecoSH
        try {
            const { nomeCliente, emailFuncLogistica, emailVendedor, emailAsTec, emailSac, emailSupProjetos } = this.state.contractDetails;

            console.log(selectedUserContract);

            let toList = [];

            if (selectedUserContract) {
                toList.push(`operacoes_${selectedUserContract.nomeUnidade}@sh.com.br`);
            }

            if (emailFuncLogistica)
                toList.push(emailFuncLogistica);
            if (emailVendedor)
                toList.push(emailVendedor);
            if (emailAsTec)
                toList.push(emailAsTec);
            if (emailSupProjetos)
                toList.push(emailSupProjetos);

            toList.push("thiago.neves@kroonar.com");

            console.log(emailFuncLogistica, emailVendedor, emailAsTec, emailSac, emailSupProjetos);

            console.log('lista de emails');
            console.log(toList);

            await _api.post('/mail', {
                to: toList,
                type: action,
                user: nomeCliente,
                email: emailSac,
                actionText,
                truckSize: tamanhoCaminhao,
                fullAddress,
                date, unity
            });
            // await _api.post('/mail', {
            //     to: ['operacoes_BSB@sh.com.br'],
            //     type: action,
            //     user: nomeCliente,
            //     email: emailSac,
            //     actionText,
            //     truckSize: tamanhoCaminhao,
            //     fullAddress,
            //     date, unity
            // });

            this.setState({ loading: false })
            this.clearForm()
            NotificationManager.success(
                "Solicitação de agendamento confirmada. Aguarde confirmação ou sugestão de nova data por e-mail!\nObrigado. Equipe de Logística",
                "Sucesso",
                10000
            )
        } catch (err) {
            this.setState({ loading: false })
            NotificationManager.error(
                "Error ao enviar solicitação, tente novamente mais tarde",
                "Erro",
                4000
            )
            throw err
        }
    };

    setupDrives = () => {
        util.itemsList(this.state.contractId).then(productList => {
            for (let product of productList) {
                product.quantity = 0;
                product.valorUnitario = product.valor / product.qtdeObra;
            }
            this.setState({ productList });
        });

        api.get("/api/filial/all").then(response => {
            const addressListArray = [];
            for (let branch of response.data) {
                let text = `${branch.uf} - ${branch.logradouro}, ${branch.numero} - ${branch.cep}, ${branch.cidade}`

                addressListArray.push(text);
            }

            this.setState({
                // addressList: [...this.state.addressList, text]
                addressList: addressListArray
            });
        });
    };

    calculate = () => {
        let total = 0;
        for (let product of this.state.productList) {
            if (product.quantity > 0) {
                total += product.valorUnitario * parseInt(product.quantity);
            }
        }
        // let distance = this.getDistance();
        this.setState({ total: `R$ ${util.currenzialize(total)}` });
    };

    getDistance = async () => {
        let { rua, numero, cidade, enderecoRetirada } = this.state;
        await axios
            .get(
                `http://www.mapquestapi.com/directions/v2/route?key=9FEaUfVEosiYqFUecWaO9kMAXqs1ccGn&from=${enderecoRetirada}&to=${`${rua} ${numero}, ${cidade}`}`
            )
            .then(response => {
                return response.data.route.distance;
            })
            .catch(error => {
                console.log(error);
            });
    };

    componentWillMount = () => {
        api.defaults.headers.common = {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("token")).accessToken
                }`
        };
        _api.defaults.headers.common = {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("_token")).accessToken
                }`
        };
        console.log(this.state.contractId);
        this.getContractDetails(this.state.contractId);
        this.getContracts(this.state.contractId);
        this.setupDrives();
        // document.title = `${document.title} - Nova Movimentação`
    };
    // UNSAFE_componentWillMount = () => {
    //     api.defaults.headers.common = {
    //         Authorization: `Bearer ${JSON.parse(localStorage.getItem("token")).accessToken
    //             }`
    //     };
    //     _api.defaults.headers.common = {
    //         Authorization: `Bearer ${JSON.parse(localStorage.getItem("_token")).accessToken
    //             }`
    //     };
    //     this.getContractDetails(this.state.contractId);
    //     this.getContracts(this.state.contractId);
    //     this.setupDrives();
    //     // document.title = `${document.title} - Nova Movimentação`
    // };

    render() {
        return (
            <>
                <div className="drives-container">
                    <NotificationContainer />
                    {this.state.redirect !== "" ? (
                        <Redirect to={`${this.state.redirect}`} />
                    ) : null}
                    <Header />
                    {/* <Breadcrumbs
                        items={[
                            {
                                title: "Contrato",
                                link: `/home?contract=${this.state.contractId}`
                            },
                            { title: "Nova Retirada / Devolução" }
                        ]}
                    />
                    <ContractsPicker /> */}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                        }}
                    >
                        <Sidebar />
                        <div
                            style={{
                                width: "100%"
                            }}
                        >
                            <PageTitle
                                // title='Nova Retirada / Devolução'
                                title='Novo agendamento'
                                imgPath='./icons/sidebar/menu_novo_agendamento.svg'
                            />
                            {/* <Col
                                md={{ size: 8, offset: 2 }}
                                sm={{ size: 12, offset: 0 }}
                            >
                                
                            </Col> */}
                            <div
                                className="page-body"
                            >
                                <Row className="options-icons">
                                    <Col xs={12}>
                                        <Link
                                            className="link"
                                            to={`/checklist?contract=${this.state.contractId}`}
                                            style={{
                                                display: 'flex',

                                            }}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M0 0h24v24H0z" fill="none" />
                                                <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z" />
                                            </svg>
                                            <h4 className="descriptionItem">
                                                {/* Inventário Mudança de nome, de "Checklist" para "Inventário". */}

                                                Saldo de Equipamento em Obra {/* Mudança de nome, de "Inventário" para "Saldo de Equipamento em Obra".*/}
                                            </h4>
                                        </Link>
                                    </Col>
                                </Row>
                                <form onSubmit={this.handleSubmit}>
                                    <Row>
                                        <Col
                                            className="config-step"
                                            xs={12}
                                        >
                                            <Row>
                                                <Col style={{ border: "1px solid grey", padding: ".75rem 1.25rem", borderRadius: 5 }}>
                                                    <p className="step-title">
                                                        CONTRATO{" "}
                                                        <span className="highlight-text">
                                                            {
                                                                this.state.contractDetails
                                                                    .numeroContrato
                                                            }
                                                        </span>
                                                    </p>
                                                    <div className="step-container contract-details">
                                                        <p>
                                                            Obra:{" "}
                                                            <span className="highlight-text">
                                                                {
                                                                    this.state.contractDetails
                                                                        .nmObra
                                                                }
                                                            </span>
                                                            <br />
                                                            Cliente:{" "}
                                                            <span className="highlight-text">
                                                                {
                                                                    this.state.contractDetails
                                                                        .nomeCliente
                                                                }
                                                            </span>
                                                            <br />
                                                            Contratante:{" "}
                                                            <span className="highlight-text">
                                                                {
                                                                    this.state.contractDetails
                                                                        .nomeContratante
                                                                }
                                                            </span>
                                                        </p>
                                                        <hr />
                                                        <p>
                                                            Venda:{" "}
                                                            <span className="highlight-text">
                                                                {
                                                                    this.state.contractDetails
                                                                        .nomeVendedor
                                                                }
                                                            </span>
                                                            <br />
                                                            Logistica:{" "}
                                                            <span className="highlight-text">
                                                                {
                                                                    this.state.contractDetails
                                                                        .emailFuncLogistica
                                                                }
                                                            </span>
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col
                                            className="config-step"
                                            xs={12}
                                        >
                                            <Row>
                                                <Col xs={12} className="pl-0">
                                                    <div class="alert alert-danger w-100">
                                                        <h4 class="alert-heading">Atenção!</h4>
                                                        <p class="font-weight-normal">Esse procedimento é apenas de solicitação de agendamento e depende da confirmação da equipe de logística da SH.</p>
                                                        <hr />
                                                        <p class="mb-0 font-weight-normal">Aguarde um retorno em breve!</p>
                                                    </div>
                                                    <p className="step-title">
                                                        AGENDAR PARA:
                                                    </p>
                                                    <div className="step-container availability">
                                                        <FormGroup>
                                                            <Input
                                                                required
                                                                style={{ marginBottom: 10 }}
                                                                placeholder="Data"
                                                                value={this.state.data}
                                                                onChange={e => {
                                                                    this.setState({
                                                                        data: e.target.value
                                                                    });
                                                                }}
                                                                type="date"
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {/* <Row>
                                                <Button
                                                    className="btn-hollow d-block"
                                                    style={buttonTheme}
                                                    block={window.innerWidth <= 1000}>
                                                    {
                                                        this.state.loading
                                                            ? <Spinner style={{ height: 25, width: 25 }} />
                                                            : 'SOLICITAR'
                                                    }
                                                </Button>
                                            </Row> */}
                                        </Col>
                                        <Col
                                            className="config-step"
                                            xs={12}
                                        >
                                            <Row>
                                                <p className="step-title">
                                                    TIPO DE OPERAÇÃO:
                                                </p>
                                                <div className="step-container action">
                                                    <select
                                                        className="form-control"
                                                        required
                                                        onChange={e => {
                                                            this.setState({
                                                                action: e.target.value
                                                            });
                                                        }}
                                                        value={this.state.action}
                                                    >
                                                        <option value="">Selecione</option>
                                                        <option value="Retirada">Solicitar retirada</option>
                                                        <option value="Devolução">Solicitar devolução</option>
                                                    </select>
                                                    {/* <div className="action-image" style={{ width: "20%" }}>
                                                        <img
                                                            className="image"
                                                            id="caminhao-sh-pwa"
                                                            src={
                                                                "./images/caminhao-sh-pwa.png"
                                                            }
                                                            alt="logo_sh"
                                                        />
                                                    </div> */}
                                                    {/* <div className="action-options">
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input
                                                                    required
                                                                    type="radio"
                                                                    value={this.state.action}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            action:
                                                                                "Retirada"
                                                                        });
                                                                    }}
                                                                    name="radio2"
                                                                />{" "}
                                                                Solicitar uma Retirada
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input
                                                                    required
                                                                    type="radio"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            action:
                                                                                "Devolução"
                                                                        });
                                                                    }}
                                                                    name="radio2"
                                                                />{" "}
                                                                Solicitar uma Devolução
                                                            </Label>
                                                        </FormGroup>
                                                        
                                                    </div> */}
                                                </div>
                                            </Row>
                                        </Col>
                                        <Col
                                            className="config-step"
                                            xs={12}
                                        >
                                            <Row>
                                                <p className="step-title">
                                                    TIPO DE CAMINHÃO:
                                                </p>
                                                <div className="step-container action">
                                                    {/* <div className="action-options">
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input
                                                                    required
                                                                    type="radio"
                                                                    value={this.state.action}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            tamanhoCaminhao:
                                                                                "Até 12 tons."
                                                                        });
                                                                    }}
                                                                    name="radio1"
                                                                />{" "}
                                                                Até 12 toneladas
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input
                                                                    required
                                                                    type="radio"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            tamanhoCaminhao:
                                                                                "Maior de 12 tons."
                                                                        });
                                                                    }}
                                                                    name="radio1"
                                                                />{" "}
                                                                Maior de 12 toneladas
                                                            </Label>
                                                        </FormGroup>
                                                    </div> */}
                                                    <select
                                                        className="form-control"
                                                        required
                                                        onChange={e => {
                                                            this.setState({
                                                                tamanhoCaminhao: e.target.value
                                                            });
                                                        }}
                                                        value={this.state.tamanhoCaminhao}
                                                    >
                                                        <option value="">Selecione</option>
                                                        <option value="Até 12 tons.">Até 12 ton</option>
                                                        <option value="Maior de 12 tons.">Maior de 12 ton</option>
                                                    </select>
                                                </div>
                                            </Row>
                                        </Col>
                                        <Col
                                            className="config-step"
                                            xs={12}
                                        >
                                            <Row>
                                                <p className="step-title">
                                                    ENDEREÇO DA OBRA:
                                                </p>
                                                <div className="step-container construction-address">
                                                    <div className="form-row">
                                                        <FormGroup className="col-sm-12" style={{ marginBottom: '4px' }}>
                                                            <InputMask
                                                                required
                                                                mask={"99999-999"}
                                                                value={this.state.cep}
                                                                onChange={e => {
                                                                    this.setState({
                                                                        cep: e.target.value
                                                                    });
                                                                }}
                                                                onBlur={() => {
                                                                    this.cepInfos(
                                                                        this.state.cep
                                                                    );
                                                                }}
                                                                className="form-control"
                                                                placeholder="CEP"
                                                            />
                                                        </FormGroup>
                                                        <FormGroup className="col-sm-12" style={{ marginBottom: '4px' }}>
                                                            <Input
                                                                required
                                                                disabled={this.state.ceploading}
                                                                value={this.state.cidade}
                                                                onChange={e => {
                                                                    this.setState({
                                                                        cidade: e.target.value
                                                                    });
                                                                }}
                                                                placeholder="Cidade"
                                                            />
                                                        </FormGroup>
                                                    </div>

                                                    <div className="form-row">
                                                        <FormGroup className="col-sm-12" style={{ marginBottom: '4px' }}>
                                                            <Input
                                                                required
                                                                disabled={this.state.ceploading}
                                                                value={this.state.rua}
                                                                onChange={e => {
                                                                    this.setState({
                                                                        rua: e.target.value
                                                                    });
                                                                }}
                                                                placeholder="Endereço"
                                                            />
                                                        </FormGroup>
                                                        <FormGroup className="col-sm-12" style={{ marginBottom: '4px' }}>
                                                            <Input
                                                                required
                                                                value={this.state.numero}
                                                                onChange={e => {
                                                                    this.setState({
                                                                        numero: e.target.value
                                                                    });
                                                                }}
                                                                placeholder="Número"
                                                            />
                                                        </FormGroup>
                                                    </div>

                                                    <div className="form-row">
                                                        <FormGroup className="col-12">
                                                            <Input
                                                                // required
                                                                value={this.state.comp}
                                                                onChange={e => {
                                                                    this.setState({
                                                                        comp: e.target.value
                                                                    });
                                                                }}
                                                                placeholder="Complemento"
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </Row>
                                        </Col>
                                        <Col
                                            className="config-step"
                                            xs={12}
                                        >
                                            <Row>
                                                <Col xs={12} className="pl-0">
                                                    <p className="step-title">
                                                        UNIDADE SH:
                                                    </p>
                                                    <div className="step-container sh-unit">
                                                        <FormGroup>
                                                            <select
                                                                className="form-control"
                                                                required
                                                                onChange={e => {
                                                                    this.setState({
                                                                        enderecoSH:
                                                                            e.target.value
                                                                    });
                                                                }}
                                                                value={this.state.enderecoRetirada}
                                                            >
                                                                <option value="">
                                                                    {this.state.addressList
                                                                        .length > 0
                                                                        ? "Selecione uma unidade"
                                                                        : "Carregando unidades..."}
                                                                </option>
                                                                {this.state.addressList.map(
                                                                    (value, i) => {
                                                                        return (
                                                                            <option
                                                                                key={i}
                                                                                className="address"
                                                                            >
                                                                                {value}
                                                                            </option>
                                                                        );
                                                                    }
                                                                )}
                                                            </select>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Button
                                                    className="btn-hollow d-block"
                                                    style={buttonTheme}
                                                    block={window.innerWidth <= 1000}>
                                                    {
                                                        this.state.loading
                                                            ? <Spinner style={{ height: 25, width: 25 }} />
                                                            : 'SOLICITAR'
                                                    }
                                                </Button>
                                            </Row>
                                        </Col>

                                    </Row>
                                    <Row>
                                    </Row>
                                </form>
                                <Row>
                                    <Col md={{ size: 12 }}>
                                        <a href="/commercialvisit">
                                            <img
                                                style={{
                                                    margin: "auto",
                                                    marginTop: "55px", width: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                                src={"./images/banners/agendamento.png"}
                                            />
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Drives;
