import React, { Component } from "react";
import Header from "../../components/header";
import ContractPicker from "../../components/contract_selector";
import BodyPictures from "../../components/body_pictures";
import Breadcrumbs from "../../components/breadcrumbs";
import util from "../../util";
import "./styles.css";

import ReactGA from "react-ga";

class Pictures extends Component {
    constructor() {
        super();
        this.state = {
            // contractId: util.queryParams().contract
            // contractId: util.queryParams().contract ?
            //     util.queryParams().contract
            //     : "0",
            contractId: util.queryParams().contract ?
                util.queryParams().contract
                : JSON.parse(localStorage.getItem('userContract')).propostaContratoGuid,
        };
        ReactGA.initialize("UA-173154606-1");
        ReactGA.pageview("/fotosDaObra");
    }

    componentDidMount = () => {
        // document.title = `${document.title} - Fotos da Obra`
    };

    render() {
        return (
            <div className="pictures-container">
                <Header />
                <Breadcrumbs
                    items={[
                        {
                            title: "Contrato",
                            link: `/home?contract=${this.state.contractId}`
                        },
                        { title: "Fotos da Obra" }
                    ]}
                />
                <ContractPicker />
                <BodyPictures />
            </div>
        );
    }
}

export default Pictures;
