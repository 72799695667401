import React, { Component } from "react";
import {
    Label,
    Input,
    Form,
    FormGroup,
    Row,
    Col,
    Button,
    Table,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter
} from "reactstrap";
import api from "../../Services/api.js";
import axios from "axios";
import util from "../../util";
import Header from "../../components/header";
import InputMask from "react-input-mask";
import { Redirect, Link } from "react-router-dom";
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
import ContractsPicker from "../../components/contract_selector";
import Breadcrumbs from "../../components/breadcrumbs";
import moment from "moment";
import "moment/locale/pt-br";
import { button as buttonTheme } from "../../themes";

import "./styles.css";
// import { Container } from './styles';

import ReactGA from "react-ga";

export default class FreightCalculator extends Component {
    constructor() {
        super();
        this.state = {
            // contractId: util.queryParams().contract,
            // contractId: util.queryParams().contract ?
            //     util.queryParams().contract
            //     : "0",
            contractId: util.queryParams().contract ?
                util.queryParams().contract
                : JSON.parse(localStorage.getItem('userContract')).propostaContratoGuid,

            cep: "",
            cidade: "",
            rua: "",
            numero: "",
            comp: "",
            productList: [],
            addressList: [],
            enderecoRetirada: "",
            tipoCaminhao: "",
            eixos: 0,
            distancia: 0,
            distanciaTotal: 0,
            ccTotal: 0,
            frete: 0,
            currentBalance: 0,
            redirect: "",
            ceploading: false,
            loading: false,
            resultModal: false
        };

        ReactGA.initialize("UA-173154606-1");
        ReactGA.pageview("/calculoFrete");
    }

    cepInfos = async cep => {
        this.setState({ ceploading: true });
        await axios
            .get(`https://viacep.com.br/ws/${cep}/json/`)
            .then(response => {
                let { logradouro, localidade } = response.data;
                this.setState({ rua: logradouro, cidade: localidade });
            })
            .catch(error => {
                NotificationManager.error(
                    "Não conseguimos localizar seu endereço.",
                    "CEP incorreto",
                    5000
                );
            });
        this.setState({ ceploading: false });
    };

    setupCalculator = async () => {
        api.get("/api/filial/all").then(response => {
            for (let branch of response.data) {
                let text = `${branch.uf} - ${branch.logradouro}, ${branch.numero} - ${branch.cep}, ${branch.cidade}`;
                this.setState({
                    addressList: [...this.state.addressList, text]
                });
            }
        });
        api.get(
            `/api/contrato/${this.state.contractId}/saldo/${moment().format(
                "YYYY-MM-DD"
            )}`
        ).then(resp => {
            let currentBalance = 0;
            for (let item of resp.data) {
                currentBalance += item.valor;
            }
            this.setState({ currentBalance: currentBalance.toFixed(2) });
        });

        let productList = await util.itemsList(this.state.contractId);
        for (let product of productList) {
            product.quantity = 0;
            product.selected = false;
            product.valorUnitario = product.valor / product.qtdeObra;
        }

        this.setState({ productList });
    };

    calculate = async e => {
        e.preventDefault();
        let total = 0;
        let productTotal = 0;
        for (let product of this.state.productList) {
            if (product.quantity > 0) {
                productTotal +=
                    product.valorUnitario * parseInt(product.quantity);
            }
        }
        if (productTotal === 0)
            return NotificationManager.error(
                "Para realizar o calculo de frete é necessário que você selecione os produtos que deseja movimentar",
                "Não existem produtos selecionados",
                5000
            );
        this.setState({ loading: true });

        await this.getDistance();
        let distanceTotal = 400.0;
        if (this.state.eixos == 6)
            distanceTotal = this.state.distancia * 3.4525; // CCDce
        if (this.state.eixos == 5)
            distanceTotal = this.state.distancia * 3.0033;
        if (this.state.eixos == 3)
            distanceTotal = this.state.distancia * 2.1334;

        let ccTotal =
            this.state.eixos == 6
                ? 283.31
                : this.state.eixos == 5
                ? 243.21
                : this.state.eixos == 3
                ? 196.4
                : 0; // CCce
        this.setState({
            distanciaTotal: `R$ ${util.currenzialize(distanceTotal)}`,
            ccTotal: `R$ ${util.currenzialize(ccTotal)}`
        });
        total = distanceTotal + ccTotal;
        this.setState({
            resultModal: true,
            frete: `R$ ${util.currenzialize(total)}`,
            loading: false
        });
    };

    getDistance = async () => {
        let { rua, numero, cidade, enderecoRetirada } = this.state;
        await axios
            .get(
                `http://www.mapquestapi.com/directions/v2/route?key=9FEaUfVEosiYqFUecWaO9kMAXqs1ccGn&from=${enderecoRetirada}&to=${`${rua} ${numero}, ${cidade}`}`
            )
            .then(response => {
                this.setState({ distancia: response.data.route.distance });
            })
            .catch(error => {
                console.log(error);
            });
    };

    componentWillMount = () => {
        api.defaults.headers.common = {
            Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("token")).accessToken
            }`
        };
        this.setupCalculator();
    };

    render() {
        return (
            <div className="freightcalculator-container">
                <NotificationContainer />
                <Header />
                <Breadcrumbs
                    items={[
                        {
                            title: "Home",
                            link: `/home?contract=${this.state.contractId}`
                        },
                        {
                            title: "Nova Movimentação",
                            link: `/drives?contact=${this.state.contractId}`
                        },
                        { title: "Calculadora de Frete" }
                    ]}
                />
                <ContractsPicker />
                <br />
                {/* <Row>
                    <span className="comingo-soon">Em breve...</span>
                </Row> */}
                <form onSubmit={this.calculate}>
                    <Row className="config-session">
                        <Col
                            className="address-area"
                            xs={12}
                            md={{ size: 8, offset: 2 }}
                        >
                            <h4>ENDEREÇO</h4>
                            <div className="step-container construction-address">
                                <InputMask
                                    required
                                    mask={"99999-999"}
                                    value={this.state.cep}
                                    onChange={e => {
                                        this.setState({ cep: e.target.value });
                                    }}
                                    onBlur={() => {
                                        this.cepInfos(this.state.cep);
                                    }}
                                    className="form-control"
                                    placeholder="CEP"
                                />
                                <Input
                                    required
                                    disabled={this.state.ceploading}
                                    value={this.state.cidade}
                                    onChange={e => {
                                        this.setState({
                                            cidade: e.target.value
                                        });
                                    }}
                                    placeholder="Cidade"
                                />
                                <Input
                                    required
                                    disabled={this.state.ceploading}
                                    value={this.state.rua}
                                    onChange={e => {
                                        this.setState({ rua: e.target.value });
                                    }}
                                    placeholder="Endereço"
                                />
                                <Input
                                    required
                                    value={this.state.numero}
                                    onChange={e => {
                                        this.setState({
                                            numero: e.target.value
                                        });
                                    }}
                                    placeholder="Número"
                                />
                                <Input
                                    value={this.state.comp}
                                    onChange={e => {
                                        this.setState({ comp: e.target.value });
                                    }}
                                    placeholder="Complemento"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="config-session">
                        <Col
                            className="products-area"
                            xs={12}
                            md={{ size: 8, offset: 2 }}
                        >
                            <h4>
                                PRODUTOS DESEJADOS{" "}
                                <span style={{ fontWeight: 300 }}>
                                    (produtos com 0 unidades serão
                                    desconsiderados)
                                </span>
                            </h4>
                            <div className="products-box">
                                {this.state.productList.length > 0 ? (
                                    <Table size="sm">
                                        <tbody>
                                            {this.state.productList.map(
                                                (value, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td className="nm-equip">
                                                                {
                                                                    value.equipamento
                                                                }
                                                            </td>
                                                            <td className="vl-equip">
                                                                R${" "}
                                                                {util.currenzialize(
                                                                    value.valorUnitario
                                                                )}
                                                            </td>
                                                            <td>
                                                                <Input
                                                                    className="freigh-input"
                                                                    type="number"
                                                                    defaultValue="0"
                                                                    onChange={e => {
                                                                        let newProducts = this
                                                                            .state
                                                                            .productList;
                                                                        newProducts[
                                                                            i
                                                                        ].quantity =
                                                                            e.target.value;
                                                                        this.setState(
                                                                            {
                                                                                productList: newProducts
                                                                            }
                                                                        );
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                {value.quantity
                                                                    ? `${util.currenzialize(
                                                                          value.equipPesoUn *
                                                                              parseInt(
                                                                                  value.quantity
                                                                              )
                                                                      )} KG`
                                                                    : "0 KG"}
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </tbody>
                                    </Table>
                                ) : (
                                    <span style={{ fontWeight: 300 }}>
                                        No momento não há produtos em contrato
                                    </span>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row className="config-session">
                        <Col xs={12} md={{ size: 4, offset: 2 }}>
                            <h4>TIPO DE CAMINHÃO</h4>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        onClick={e => {
                                            this.setState({
                                                tipoCaminhao: "Truck",
                                                eixos: 3
                                            });
                                        }}
                                        required
                                        type="radio"
                                        name="truck"
                                    />{" "}
                                    Truck (3 eixos)
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        onClick={e => {
                                            this.setState({
                                                tipoCaminhao: "Carreta",
                                                eixos: 5
                                            });
                                        }}
                                        required
                                        type="radio"
                                        name="truck"
                                    />{" "}
                                    Carreta (5 eixos)
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        onClick={e => {
                                            this.setState({
                                                tipoCaminhao: "Carreta",
                                                eixos: 6
                                            });
                                        }}
                                        required
                                        type="radio"
                                        name="truck"
                                    />{" "}
                                    Carreta (6 eixos)
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={{ size: 4 }}>
                            <h4>TIPO DE ENTREGA</h4>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        required
                                        type="radio"
                                        name="delivery"
                                    />{" "}
                                    Normal
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        required
                                        type="radio"
                                        name="delivery"
                                    />{" "}
                                    Imediato
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="config-session">
                        <Col xs={12} md={{ size: 8, offset: 2 }}>
                            <h4>SELECIONE A UNIDADE QUE FARÁ A ENTREGA</h4>
                            <select
                                required
                                onChange={e => {
                                    this.setState({
                                        enderecoRetirada: e.target.value
                                    });
                                }}
                                value={this.state.enderecoRetirada}
                            >
                                <option value="">
                                    {this.state.addressList.length > 0
                                        ? "Selecione uma unidade"
                                        : "Carregando unidades..."}
                                </option>
                                {this.state.addressList.map((value, i) => {
                                    return (
                                        <option key={i} className="address">
                                            {value}
                                        </option>
                                    );
                                })}
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <br />
                        <Col xs={12} md={{ size: 8, offset: 2 }}>
                            <Button
                                type="submit"
                                disabled={this.state.loading}
                                block
                                className="btn-hollow"
                                style={buttonTheme}
                            >
                                {this.state.loading
                                    ? "CALCULANDO ..."
                                    : "CALCULAR"}
                            </Button>
                        </Col>
                    </Row>
                </form>
                <Modal centered isOpen={this.state.resultModal}>
                    <ModalHeader>
                        <div
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <span>CALCULO DE FRETE - RESULTADO</span>
                            <span
                                style={{ fontSize: 14 }}
                            >{`Saldo atual: R$ ${util.currenzialize(
                                this.state.currentBalance
                            )}`}</span>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <div
                            className="result-feedback"
                            style={{
                                width: "100%",
                                heigth: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <span style={{ fontWeight: 300, fontSize: 14 }}>
                                {`Distância total (${this.state.distanciaTotal}) + Tipo de caminhão (${this.state.ccTotal})`}
                            </span>
                            <span style={{ fontWeight: 300, fontSize: 14 }}>
                                valor estimado com base nos índices da ANTT -
                                Agência Nacional de Transportes Terrestres{" "}
                            </span>
                            <span style={{ fontWeight: 300, fontSize: 26 }}>
                                {this.state.frete}
                            </span>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            className="btn-hollow"
                            style={buttonTheme}
                            onClick={() => {
                                this.setState({ resultModal: false });
                            }}
                        >
                            FECHAR
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}
