import React, { useEffect, useState } from "react";
import MultiStepProgressBar from "../MultiStepProgressBar";

const ProgressExample = ({ agendamentos, statusCaminhao }) => {
    // const [page, setPage] = useState(statusCaminhao === 'Não concluído' ? 1 : 2);
    // const [page, setPage] = useState(statusCaminhao === 'Não concluído' ? "pagetwo" : "pagethree");

    const getPageStatus = (agendamentos) => {
        let response = "pagezero";

        // if (agendamentos) {
        //     response = "pageone";
        // }

        // if (statusCaminhao && statusCaminhao === 'Não concluído') {
        //     response = "pageone";
        // }

        const lastAgendamento = agendamentos[agendamentos.length - 1];

        if (lastAgendamento && lastAgendamento.statusCaminhao) {
            switch (lastAgendamento.statusCaminhao) {
                case 0:
                    response = "pagezero";
                    break;
                case 1:
                    response = "pagetwo";
                    break;
                case 2:
                    response = "pagethree";
                    break;
                case 3:
                    response = "pagefour";
                    break;

                // 0= não chegou
                // 1= chegou e não iniciou a operação
                // 2= chegou e iniciou a operação
                // 3= finalizou a operação
            }
        }

        return response;
    };

    const [page, setPage] = useState(getPageStatus(agendamentos));

    const nextPage = (page) => {
        setPage(page);
    };

    const nextPageNumber = (pageNumber) => {
        // switch (pageNumber) {
        //     case "1":
        //         setPage("pageone");
        //         break;
        //     case "2":
        //         setPage("pagetwo");
        //         break;
        //     case "3":
        //         setPage("pagethree");
        //         break;
        //     case "4":
        //         setPage("pagefour");
        //         break;
        //     default:
        //         setPage("0");
        // }
    };

    // useEffect(() => {
    //     getPageStatus(agendamentos);
    // }, [])

    return (
        <MultiStepProgressBar page={page} onPageNumberClick={nextPageNumber} />
    );
};

export default ProgressExample;
