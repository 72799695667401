import React, { useState, useEffect } from "react";
import { Input, Tooltip } from "reactstrap";
import { Link } from "react-router-dom";
import util from "../../util";
import "./styles.css";
import api from "../../Services/api.js";
import ContractsPicker from "../../components/contract_selector";
import MobileSidebar from "../../components/mobileSideBar";

const Header = props => {
    const [tooltipProfile, setTooltipProfile] = useState(false);
    const [tooltipMap, setTooltipMap] = useState(false);
    const [tooltipMedia, setTooltipMedia] = useState(false);
    const [tooltipCatalog, setTooltipCatalog] = useState(false);
    const [tooltipShowroom, setTooltipShowroom] = useState(false);
    const [tooltipLoadUnloadReport, setTooltipLoadUnloadReport] = useState(false);
    const [userId] = useState((
        (localStorage.getItem("token")) ? (JSON.parse(localStorage.getItem("token")).userId) : (null)
    ));
    const [userInfo, setUserInfo] = useState({});
    const [isMobileSideBarOpen, setIsMobileSideBarOpen] = useState(false);

    useEffect(() => {
        const getUserInfos = () => {
            api.get(`/api/usuario/${userId}`)
                .then(response => {
                    setUserInfo(response.data);
                })
                .catch(e => {
                    console.log(e);
                });
        };

        getUserInfos();
    }, [userId])

    return (
        <>
            <nav id="header">
                <div className="container mobile">
                    <div className="top-items">
                        <div
                            className="header-item not-logo"
                        >
                            <a
                                href='#t'
                                className="burger"
                                onClick={() => {
                                    setIsMobileSideBarOpen(true);
                                }}
                            >
                                <img
                                    src={"./icons/header/burger-button.svg"}
                                />
                            </a>
                        </div>
                        <div
                            className="header-item logo"
                        >
                            <a
                                href={
                                    util.queryParams().contract
                                        ? `/home?contract=${util.queryParams().contract
                                        }`
                                        :
                                        `/home`
                                }
                                className="logo"
                            >
                                <img
                                    style={{
                                        width: "139px"
                                    }}
                                    src={"./images/logo.svg"}
                                />
                            </a>
                        </div>
                        <div
                            className="header-item not-logo"
                        >
                            <a
                                href='https://api.whatsapp.com/send?phone=5521988446160&text=Ol%C3%A1!'
                                target='_blank'>
                                <img id="wpp-logo"
                                    src='./icons/header/whatsapp.svg'
                                    style={{
                                        width: '40px',
                                    }}
                                    alt="Logo do WhatsApp" />
                            </a>
                        </div>
                    </div>
                    <div className="contracts-picker-container">
                        <ContractsPicker />
                    </div>
                    <div className="sidebar">
                        <MobileSidebar
                            isOpen={isMobileSideBarOpen}
                        />
                    </div>
                </div>
                <div className="container desktop">
                    <div className="container">
                        <a
                            href={
                                util.queryParams().contract
                                    ? `/home?contract=${util.queryParams().contract
                                    }`
                                    :
                                    `/home`
                            }
                            className="logo"
                        >
                            <img
                                style={{
                                    width: "97px"
                                }}
                                src={"./images/logo.svg"}
                            />
                        </a>
                        <a style={{
                            // marginLeft: "31px"
                        }}>
                            <ContractsPicker />
                        </a>
                        <div className="container icons">
                            <Link
                                id="map"
                                to={"/map"}
                                className={`icon ${props.activeTab == "map" ? "active" : ""
                                    }`}
                            >
                                <img
                                    src={
                                        "./icons/header/location-pin.svg"
                                    }
                                    style={{
                                        marginTop: "3px",
                                    }}
                                />
                                <div className="icon-banner"></div>
                            </Link>
                            <Tooltip
                                target="map"
                                placement="bottom"
                                isOpen={tooltipMap}
                                toggle={() => setTooltipMap(!tooltipMap)}
                            >
                                Mapa
                            </Tooltip>

                            <Link
                                id="media"
                                to="/media"
                                className={`icon ${props.activeTab == "media" ? "active" : ""
                                    }`}
                            >
                                <img
                                    src={
                                        "./icons/header/smile.svg"
                                    }
                                    style={{
                                        marginTop: "0px",
                                    }}
                                />
                                <div className="icon-banner"></div>
                            </Link>
                            <Tooltip
                                target="media"
                                placement="bottom"
                                isOpen={tooltipMedia}
                                toggle={() => setTooltipMedia(!tooltipMedia)}
                            >
                                Mídia
                            </Tooltip>
                            <Link
                                id="catalog"
                                to={"/catalog"}
                                className={`icon ${props.activeTab == "catalog" ? "active" : ""
                                    }`}
                            >
                                <img
                                    src={
                                        "./icons/header/note.svg"
                                    }
                                    style={{
                                        marginTop: "0px",
                                    }}
                                />
                                <div className="icon-banner"></div>
                            </Link>
                            <Tooltip
                                target="catalog"
                                placement="bottom"
                                isOpen={tooltipCatalog}
                                toggle={() => setTooltipCatalog(!tooltipCatalog)}
                            >
                                Documentos importantes
                            </Tooltip>
                            <a
                                id="showroom"
                                href='https://sh.com.br/showroom/'
                                target='_blank'>
                                <img
                                    src={
                                        "./icons/header/lamp.svg"
                                    }
                                    style={{
                                        marginTop: "-14px",
                                    }}
                                />
                                <div className="icon-banner"></div>
                            </a>
                            <Tooltip
                                target="showroom"
                                placement="bottom"
                                isOpen={tooltipShowroom}
                                toggle={() => setTooltipShowroom(!tooltipShowroom)}
                            >
                                Showroom
                            </Tooltip>
                        </div>
                    </div>
                    <div
                        style={{
                            margin: "auto",
                            marginRight: "94px",
                            whiteSpace: 'nowrap',
                            display: 'flex',
                        }}
                    >
                        <div
                            className="profile-name"
                            style={{
                                margin: "auto",
                            }}
                        >
                            <a
                                id="profile"
                                href="/user"
                                style={{
                                    color: '#A38136',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                    marginRight: "0px",
                                    textTransform: 'uppercase'
                                }}
                            >
                                {(userInfo.nome) && (
                                    <>
                                        {userInfo.nome}
                                        <img
                                            src={
                                                "./icons/header/arrow-down.svg"
                                            }
                                            style={{
                                                marginTop: "0px",
                                                marginLeft: "7px",
                                            }}
                                        />
                                    </>
                                )}
                            </a>
                            <Tooltip
                                target="profile"
                                placement="bottom"
                                isOpen={tooltipProfile}
                                toggle={() => setTooltipProfile(!tooltipProfile)}
                            >
                                Perfil
                            </Tooltip>
                        </div>
                        <a
                            href='https://api.whatsapp.com/send?phone=5521988446160&text=Ol%C3%A1!'
                            target='_blank'>
                            <img id="wpp-logo"
                                src='./icons/header/whatsapp.svg'
                                style={{
                                    width: '40px',
                                }}
                                alt="Logo do WhatsApp" />
                        </a>
                        {/* <a
                        href='https://surveyv2.binds.co/#/link/alR8o2dVGBQgr319xqQ01AqLU6yAdYy57Z3n5Yk6MjwL1vXN/?loop=true&close=true&timeout=30&'
                        target='_blank'>
                        <img id="satisfaction-logo"
                            // src='./images/green-simley.jpg'
                            src='./icons/header/smile_feedback.svg'
                            alt="Pesquisa de satisfação"
                            style={{
                                borderRadius: '50%',
                                maxHeight: '37px',
                                marginTop: '2px',
                                maxWidth: '37px',
                            }}
                        />
                    </a> */}
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Header;
