import React from "react";
import Routes from "./routes";
import "./app.css";
import ReactGA from "react-ga";
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';

ReactGA.initialize("UA-173154606-1");
TagManager.initialize({ gtmId: 'GTM-MK3P8B9' });

const App = () => (
    <div className="App">
        <Helmet>
            {/* ok */}
            <meta httpEquiv="X-Content-Type-Options" content="nosniff" />
            <meta httpEquiv="X-XSS-Protection" content="1; mode=block" />
            {/* ok */}
            
            {/* NOT-ok */}
            
            {/* <meta httpEquiv="Content-Security-Policy" content="default-src 'self'; img-src 'self' data:; style-src 'self' 'unsafe-inline'; script-src 'self' 'unsafe-inline' 'unsafe-eval'" /> */}
            {/* <meta httpEquiv="X-Frame-Options" content="DENY" /> */}

            
            {/* NOT-ok */}

        </Helmet>
        <Routes />
    </div>
);

export default App;
