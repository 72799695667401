import React, { useState, useEffect } from 'react';
import api from "../../Services/api.js";
import util from '../../util';
import { Redirect, Link } from 'react-router-dom';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import './styles.css'
import ModalContractDetails from '../modal-contract-details'
import { FaPlusCircle } from 'react-icons/fa';

const ContractsPicker = (props) => {

    const [redirect, setRedirect] = useState('')
    const [userId] = useState(JSON.parse(localStorage.getItem('token')).userId)
    const [contractId, setContractId] = useState(util.queryParams().contract ? util.queryParams().contract : "0")
    const [modalDetails, setModalDetails] = useState(false)
    const [userContracts, setUserContracts] = useState([])
    const [userContractsFiltro, setUserContractsFiltro] = useState([])
    const [selectedUserContract, setSelectedUserContract] = useState(JSON.parse(localStorage.getItem('userContract')) || {})
    const [dropdown, setDropDown] = useState(false)

    useEffect(() => {
        api.defaults.headers.common = { "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}` };
        getContracts();
    }, [])

    const getContracts = () => {
        console.log('selectedUserContract');
        console.log(selectedUserContract);

        const updatedContract = selectedUserContract;
        if (!updatedContract.idPropostaContratoInt) {
            updatedContract.idPropostaContratoInt = updatedContract.idPropostaContrato;
            updatedContract.idPropostaContrato = updatedContract.propostaContratoGuid;
            setSelectedUserContract(updatedContract);
        }

        api.get(`/api/usuario/${userId}/contratos`)
            .then(response => {
                let userContracts = response.data;
                console.log('contratos');
                console.log(response.data);
                userContracts.sort((a, b) => b.anoProposta - a.anoProposta || b.idPropostaContrato - a.idPropostaContrato);

                response.data.forEach(contract => {
                    contract.idPropostaContratoInt = contract.idPropostaContrato;
                    contract.idPropostaContrato = contract.propostaContratoGuid;
                });

                userContractsFiltro.forEach(contract => {
                    contract.idPropostaContratoInt = contract.idPropostaContrato;
                    contract.idPropostaContrato = contract.propostaContratoGuid;
                });

                setUserContracts(userContracts);
                setUserContractsFiltro(userContracts);

                // if (contractId == '0' && userContracts.length > 0) {
                //     setContract(response.data[0].idPropostaContrato, response.data);
                // }
                // if (!selectedUserContract) {
                //     setContract(response.data[0].idPropostaContrato, response.data);
                // }
                // else {
                //     if (selectedUserContract && (!contractId || contractId === '0')) {
                //         if (!selectedUserContract.idPropostaContrato) {
                //             setContract(response.data[0].idPropostaContrato, response.data);
                //         }
                //         else {
                //             setContractId(selectedUserContract.idPropostaContrato);
                //         }
                //         // setContract(selectedUserContract.idPropostaContrato, response.data);
                //     }
                // }
                if ((!selectedUserContract) || (!selectedUserContract.idPropostaContrato)) {
                    setContract(response.data[0].propostaContratoGuid, response.data);
                }
                else {
                    if (selectedUserContract && (!contractId || contractId === '0')) {
                        if (!selectedUserContract.propostaContratoGuid) {
                            setContract(response.data[0].propostaContratoGuid, response.data);
                        }
                        else {
                            setContractId(selectedUserContract.propostaContratoGuid);
                        }
                        // setContract(selectedUserContract.idPropostaContrato, response.data);
                    }
                }
            }).catch(error => {
                console.log(error);
            })
    }

    const setContract = async (id, data) => {
        const dataUserContracts = data ? data : userContractsFiltro
        const userSelected = dataUserContracts.find(e => e.propostaContratoGuid === id)
        
        localStorage.setItem('userContract', JSON.stringify(userSelected))
        setSelectedUserContract(userSelected)
        let { origin, pathname } = window.location
        window.location = id == 0 ? `${origin}/home` : `${origin}${pathname}?contract=${id}`
    }

    const renderContracts = () => {
        return userContractsFiltro.map((value, index) => {
            return (
                <>
                    {/* <DropdownItem
                        className='value'
                        key={value.idPropostaContrato}
                        onClick={() => setContract(value.idPropostaContrato)}>
                        <span>{value.numeroProposta ? value.numeroProposta.toString().padStart(4, "0") : ""}/{value.anoProposta}-{value.nomeUnidade} - {value.nomeObra}</span>
                    </DropdownItem> */}
                    <DropdownItem
                        className='value'
                        key={value.propostaContratoGuid}
                        onClick={() => setContract(value.propostaContratoGuid)}>
                        <span>{value.numeroProposta ? value.numeroProposta.toString().padStart(4, "0") : ""}/{value.anoProposta}-{value.nomeUnidade} - {value.nomeObra}</span>
                    </DropdownItem>
                    {
                        userContractsFiltro.length - 1 !== index
                            ? <DropdownItem divider />
                            : null
                    }
                </>
            )
        })
    }

    const searchContrato = (e) => {
        let filtro = e.target.value;

        let contratosFiltrados = userContracts.filter(x => x.nomeObra.toLowerCase().includes(filtro.toLowerCase()) || x.numeroProposta.toString().padStart(4, "0").includes(filtro) || x.anoProposta.toString().includes(filtro) || x.nomeUnidade.toString().includes(filtro));

        setUserContractsFiltro(contratosFiltrados);
    }

    return (
        <>
            {
                contractId == 0 ?
                    <br />
                    : (
                        <div>
                            <br />
                            <Row className="selector-container">
                                <Col>
                                    <Dropdown isOpen={dropdown} toggle={() => setDropDown(!dropdown)}>
                                        <DropdownToggle tag='div'>
                                            <div className="session-selector">
                                                <div className='col' style={{ cursor: 'pointer' }}>
                                                    <ModalContractDetails
                                                        modal={modalDetails}
                                                        setModal={() => setModalDetails(!modalDetails)}
                                                        {...selectedUserContract}
                                                    />
                                                    <span className='value'>
                                                        {selectedUserContract ? `${selectedUserContract.numeroProposta ? selectedUserContract.numeroProposta.toString().padStart(4, "0") : ""}/${selectedUserContract.anoProposta}-${selectedUserContract.nomeUnidade} - ${selectedUserContract.nomeObra}` : ""}
                                                    </span>
                                                </div>
                                                <div style={{ cursor: 'pointer' }}>
                                                    <img
                                                        src={
                                                            "./icons/header/arrow-down.svg"
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem toggle={false}>
                                                <input type="search" onChange={searchContrato} className="form-control contract-selector-search" />
                                            </DropdownItem>
                                            {renderContracts()}
                                        </DropdownMenu>
                                    </Dropdown>
                                </Col>
                                {props.details && contractId !== '0' ? <Col xs={{ size: 2 }}>
                                    <Link onClick={() => setModalDetails(!modalDetails)}>
                                        <FaPlusCircle className="mobile-only" style={{ fill: "#e42c2a" }} />
                                    </Link>
                                </Col> : null}
                            </Row>
                            <br />
                        </div>
                    )
            }
        </>
    )
}

export default ContractsPicker