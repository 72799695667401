import React, { Component } from "react";
import {
    Table,
    Row,
    Col,
    Input,
    Button,
    Form,
    FormGroup,
    Label
} from "reactstrap";
import api from "../../Services/api.js";
import util from "../../util";
import Header from "../../components/header";
import InputMask from "react-input-mask";
import { Redirect, Link } from "react-router-dom";
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
// import ContractsPicker from "../../components/contract_selector";
// import Breadcrumbs from "../../components/breadcrumbs";
import PageTitle from "../../components/pageTitle";
import Sidebar from "../../components/sidebar";
import moment from "moment";
import "moment/locale/pt-br";
import jsPDF from "jspdf";
import "jspdf-autotable";
import exportFromJSON from 'export-from-json'

import "./styles.css";

import ReactGA from "react-ga";

export default class Moves extends Component {
    constructor() {
        super();
        this.state = {
            redirect: "",
            // contractId: util.queryParams().contract,
            // contractId: util.queryParams().contract ?
            //     util.queryParams().contract
            //     : "0",
            contractId: util.queryParams().contract ?
                util.queryParams().contract
                : JSON.parse(localStorage.getItem('userContract')).propostaContratoGuid,
            initialDate: moment(new Date())
                .subtract(1, "year")
                .format("YYYY-MM-DD"),
            // initialDate: moment(new Date())
            //     .subtract(2, "year")
            //     .format("YYYY-MM-DD"),
            finalDate: moment(new Date()).format("YYYY-MM-DD"),
            moves: [],
            invoices: [],
            loading: false
        };
        ReactGA.initialize("UA-173154606-1");
        ReactGA.pageview("/movimentcoes");
    }

    searchMoves = async (key, value) => {
        this.setState({ moves: [], invoices: [] });
        if (key && value) {
            this.setState({ [`${key}`]: value });
        }
        let { contractId, initialDate, finalDate } = this.state;
        if (key) {
            if (key === "initialDate") initialDate = value;
            else finalDate = value;
        }
        await api
            .get(
                `/api/contrato/${contractId}/movimentacoes/${initialDate}/${finalDate}`
            )
            .then(response => {
                console.log(`/api/contrato/${contractId}/movimentacoes/${initialDate}/${finalDate}`);
                const invoices = [];
                console.log('resposta de movimentações');
                console.log(response.data);

                for (let move of response.data) {
                    const invoiceExists = invoices.some(
                        invoice => invoice.nrNotaFiscal === move.nrNotaFiscal
                    );
                    if (!invoiceExists) {
                        const {
                            nrNotaFiscal,
                            dtSaidaEntrada,
                            vlNotaFiscal,
                            nmTrecho,
                            tipo,
                            vlPeso,
                        } = move;
                        invoices.push({
                            nrNotaFiscal,
                            dtSaidaEntrada,
                            vlNotaFiscal,
                            nmTrecho,
                            tipo,
                            vlPeso,
                        });
                    }
                }

                this.setState({ moves: response.data, invoices });
            })
            .catch(e => {
                console.log(e);
            });
    };

    componentWillMount = () => {
        api.defaults.headers.common = {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("token")).accessToken
                }`
        };
        console.log(`chamando movimentações pela primeira vez`);

        this.searchMoves();
    };

    renderMoves = (nrNotaFiscal) => {
        return this.state.moves.map((value, i) => {
            if (nrNotaFiscal === value.nrNotaFiscal) {
                return (
                    <tr key={i}>
                        <td>{value.cdEquipamento}</td>
                        <td>{value.nmEquipamento}</td>
                        {/* <td>{value.vlPeso
                            .toFixed(2)
                            .toString()
                            .replace(".", ",")} Kg</td> */}
                        <td>{value.qtEquipamento}</td>
                        <td>
                            R$
                            {(value.vlUnitario * value.qtEquipamento)
                                .toFixed(2)
                                .toString()
                                .replace(".", ",")}
                        </td>
                    </tr>
                )
            }
        })
    }

    exportToExcel = () => {
        const data = []
        for (let move of this.state.moves) {
            data.push({
                ['NOTA FISCAL']: move.nrNotaFiscal,
                ['CÓDIGO']: move.cdEquipamento,
                ['EQUIPAMENTO']: move.nmEquipamento,
                // ['PESO']: move.vlPeso,
                ['QTD.']: move.qtEquipamento,
                ['LOCAÇÃO']: `R$ ${(move.vlUnitario * move.qtEquipamento).toFixed(2).toString().replace(".", ",")}`
            })
        }
        const fileName = `Movimentacoes_${this.state.contractId}`
        const exportType = 'xls'
        exportFromJSON({ data, fileName, exportType })
    }

    exportToPDF = () => {
        const doc = new jsPDF()

        function headRows() {
            return [
                {
                    cod: 'CÓDIGO',
                    equipament: 'EQUIPAMENTO',
                    amount: 'QTD.',
                    location: 'LOCAÇÃO'
                }
            ]
        }
        const bodyRows = nrNotaFiscal => {
            const body = []
            for (let move of this.state.moves) {
                if (nrNotaFiscal === move.nrNotaFiscal) {
                    body.push({
                        cod: move.cdEquipamento,
                        equipament: move.nmEquipamento,
                        amount: move.qtEquipamento,
                        location: `R$ ${(move.vlUnitario * move.qtEquipamento).toFixed(2).toString().replace(".", ",")}`
                    })
                }
            }
            return body
        }

        this.state.invoices.forEach((invoice, index) => {
            let {
                nrNotaFiscal,
                dtSaidaEntrada,
                vlNotaFiscal,
                nmTrecho,
                tipo,
                vlPeso,
            } = invoice
            dtSaidaEntrada = moment(dtSaidaEntrada).format('L')
            vlNotaFiscal = vlNotaFiscal.toFixed(2).toString().replace('.', ',')
            vlPeso = vlPeso.toFixed(2).toString().replace('.', ',')
            nmTrecho = (nmTrecho ? nmTrecho : "-");

            doc.text(
                `${(tipo === 2) ? 'NOTA DE DÉBITO' : ((tipo === 1) ? 'RE' : 'NOTA FISCAL')}: ${nrNotaFiscal} | DATA: ${dtSaidaEntrada} | VALOR: R$ ${vlNotaFiscal} | PESO TOTAL: ${(vlPeso)}${(tipo !== 1) ? ` | TRECHO: ${(nmTrecho)}` : ''}`,
                14,
                // index === 0 ? 16 : doc.lastAutoTable.finalY + 10,
                index === 0 ? 16 : doc.lastAutoTable.finalY + 30,
                {
                    maxWidth: 150
                }
            )
            doc.autoTable({
                head: headRows(),
                body: bodyRows(invoice.nrNotaFiscal),
                // startY: index === 0 ? 20 : doc.lastAutoTable.finalY + 14
                startY: index === 0 ? 60 : doc.lastAutoTable.finalY + 60
            })
        })

        const fileName = `Movimentacoes_${this.state.contractId}.pdf`
        doc.save(fileName)
        doc.setProperties({ title: fileName })
        window.open(doc.output('bloburl'))
    };

    render() {
        return (
            <div className="mymoves-container">
                <NotificationContainer />
                {this.state.redirect !== "" ? (
                    <Redirect to={`${this.state.redirect}`} />
                ) : null}
                <Header />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                    }}
                >
                    <Sidebar />
                    <div
                        style={{
                            width: "100%"
                        }}
                    >
                        <PageTitle
                            title='Movimentação'
                            imgPath='./icons/sidebar/menu_movimentacao.svg'
                        />
                        {/* <ContractsPicker /> */}
                        <div
                            className="page-body"
                        >
                            <Row>
                                {/* <Col md={{ size: 1, offset: 2 }} xs={12}>
                        <Link
                            className="link"
                            to={`/drives-moves?contract=${this.state.contractId}`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                style={{ fill: "#e42c2a" }}
                            >
                                <path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z" />
                                <path d="M0 0h24v24H0z" fill="none" />
                            </svg>
                            <h4 className="descriptionItem">
                                Histórico
                            </h4>
                        </Link>
                    </Col> */}
                                <Col md={{ size: 12 }}>
                                    <div className="session-label">PERÍODO ESPECÍFICO</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={{ size: 7 }}>
                                    <Form
                                        style={{
                                            width: "unset",
                                            maxWidth: "75%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}>
                                        <FormGroup style={{ marginBottom: 5 }}>
                                            <Input
                                                type="date"
                                                name="initialDate"
                                                value={this.state.initialDate}
                                                placeholder="__/__/____"
                                                onChange={e => {
                                                    if (
                                                        moment(
                                                            e.target.value,
                                                            "YYYY-MM-DD"
                                                        ).format("YYYY-MM-DD") ===
                                                        e.target.value
                                                    ) {
                                                        this.searchMoves(
                                                            "initialDate",
                                                            moment(e.target.value).format(
                                                                "YYYY-MM-DD"
                                                            )
                                                        );
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label
                                                style={{
                                                    marginBottom: 0,
                                                    fontSize: "11px",
                                                    color: "#9B9999"
                                                }}
                                                for="exampleEmail"
                                            >
                                                Até
                                            </Label>
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                type="date"
                                                name="finalDate"
                                                value={this.state.finalDate}
                                                placeholder="__/__/____"
                                                onChange={e => {
                                                    if (
                                                        moment(
                                                            e.target.value,
                                                            "YYYY-MM-DD"
                                                        ).format("YYYY-MM-DD") ===
                                                        e.target.value
                                                    ) {
                                                        this.searchMoves(
                                                            "finalDate",
                                                            moment(e.target.value).format(
                                                                "YYYY-MM-DD"
                                                            )
                                                        );
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col md={{ size: 5 }}>
                                    <div className="buttons-container2">
                                        <Button
                                            onClick={this.exportToPDF}
                                            className="btn-hollow"
                                        >
                                            <img
                                                src={
                                                    "./icons/download-icon.svg"
                                                }
                                            />
                                            EXPORTAR PDF
                                        </Button>
                                        <Button
                                            className="btn-hollow"
                                            onClick={this.exportToExcel}>
                                            <img
                                                src={
                                                    "./icons/download-icon.svg"
                                                }
                                            />
                                            EXPORTAR EXCEL
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={{ size: 12 }}>
                                    {this.state.moves.length > 0 ? (
                                        <div
                                            id="moves-table"
                                            size="sm"
                                            striped
                                            borderless
                                            style={{ margin: 0, padding: 0 }}>
                                            {this.state.invoices.map((invoice, index) => (
                                                <>
                                                    <thead className="invoice-content">
                                                        <tr className="stripped-head">
                                                            <th>
                                                                {`${(invoice.tipo === 2) ? 'NOTA DE DÉBITO' : ((invoice.tipo === 1) ? 'RE' : 'NOTA FISCAL')}: ${invoice.nrNotaFiscal}`}
                                                                {/* NOTA FISCAL:{" "}
                                                                {invoice.nrNotaFiscal} */}
                                                            </th>
                                                            <th>
                                                                DATA:{" "}
                                                                {moment(
                                                                    invoice.dtSaidaEntrada
                                                                ).format("L")}
                                                            </th>
                                                            {/* <th>
                                                                PESO TOTAL:{" "}
                                                                {invoice.vlPeso
                                                                    .toFixed(2)
                                                                    .toString()
                                                                    .replace(".", ",")}
                                                            </th> */}
                                                            <th>
                                                                VALOR: R$
                                                                {invoice.vlNotaFiscal
                                                                    .toFixed(2)
                                                                    .toString()
                                                                    .replace(".", ",")}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <thead className="invoice-content">
                                                        <tr className="stripped-head">
                                                            {/* <th>
                                                                TRECHO: {invoice.nmTrecho ? invoice.nmTrecho : '-'}
                                                            </th> */}
                                                            {/* Only show this <th> if invoice.tipo !== 1 */}
                                                            {invoice.tipo !== 1 && (
                                                                <th>
                                                                    TRECHO: {invoice.nmTrecho ? invoice.nmTrecho : '-'}
                                                                </th>
                                                            )}
                                                            <th>
                                                                PESO TOTAL:{" "}
                                                                {invoice.vlPeso
                                                                    .toFixed(2)
                                                                    .toString()
                                                                    .replace(".", ",")} Kg
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <div className="table-container">
                                                        <Table
                                                            size="sm"
                                                            striped
                                                            borderless>
                                                            <thead>
                                                                <tr className="stripped-head middle">
                                                                    <th>CÓDIGO</th>
                                                                    <th>EQUIPAMENTO</th>
                                                                    {/* <th>PESO</th> */}
                                                                    <th>QTD.</th>
                                                                    <th>LOCAÇÃO</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>{this.renderMoves(invoice.nrNotaFiscal)}</tbody>
                                                        </Table>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    ) : <div className="no-items">
                                        <strong>Itens não disponíveis</strong>
                                    </div>
                                    }
                                </Col>
                            </Row>

                            <Row>
                                <Col md={{ size: 12 }}>
                                    <a href="/commercialvisit">
                                        <img
                                            style={{
                                                margin: "auto",
                                                marginTop: "55px", width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                            src={"./images/banners/agendamento.png"}
                                        />
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
