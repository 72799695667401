import React, { Component } from "react";
import "../../pages/Drives/styles.css";
import {
    Table,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Spinner,
    Card,
    CardTitle,
    CardText
} from "reactstrap";
import api from "../../Services/api.js";
import util from "../../util";
import Header from "../../components/header";
import InputMask from "react-input-mask";
import { Redirect, Link } from "react-router-dom";
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
// import ContractsPicker from "../../components/contract_selector";
// import Breadcrumbs from "../../components/breadcrumbs";
import PageTitle from "../../components/pageTitle";
import Sidebar from "../../components/sidebar";
import moment from "moment";
import "moment/locale/pt-br";

import "./styles.css";

import ReactGA from "react-ga";

export default class Debits extends Component {
    constructor() {
        super();
        this.state = {
            redirect: "",

            // contractId: util.queryParams().contract,
            // contractId: util.queryParams().contract ?
            //     util.queryParams().contract
            //     : "0",
            contractId: util.queryParams().contract ?
                util.queryParams().contract
                : JSON.parse(localStorage.getItem('userContract')).propostaContratoGuid,
            debits: [],
            files: [],

            selectedDebit: null,
            infoModal: false,
            fileModal: false,
            loading: true,

            activeTab: "1"
        };
        ReactGA.initialize("UA-173154606-1");
        ReactGA.pageview("/debitos");
    }

    toggleTab = tab => {
        if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
    };

    toggleInfos = debit => {
        this.setState({
            selectedDebit: debit,
            infoModal: !this.state.infoModal
        });
    };

    toggleFileModal = debit => {
        this.setState({
            selectedDebit: debit,
            fileModal: !this.state.fileModal
        });
    };

    getContractDebits = async id => {
        const promises = [
            api.get(`/api/contrato/${id}/notasdebito`),
            api.get(`/api/Contrato/${id}/Lista/NotasDebito`)
        ];
        await Promise.all(promises).then(response => {
            for (let x = 0; x < response.length; x++) {
                let resp = response[x];
                switch (x) {
                    case 0:
                        this.setState({ debits: resp.data });
                        break;
                    case 1:
                        this.setState({ files: resp.data });
                        break;
                }
            }
        });
    };

    renderData = () => {
        return this.state.debits.map((value, i) => {
            return (
                <tr key={i}>
                    <td>{value.nrNotaDebito}</td>
                    <td>{value.dtVencimento}</td>
                    <td>
                        {value.inSituacao == 0
                            ? "Quitada"
                            : value.inSituacao == 1
                                ? "Vencida"
                                : "Em aberto"}
                    </td>
                    <td>R$ {util.currenzialize(value.vlNotaDebito)}</td>
                    <td>
                        <span title="Informações">
                            <img
                                style={{
                                    marginRight: "12px",
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    this.toggleInfos(value);
                                }}
                                src={"./icons/icone_opcoes.svg"}
                            />
                        </span>
                        <span title="Arquivos">
                            <img
                                style={{
                                    marginRight: "12px",
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    this.toggleFileModal(value);
                                }}
                                src={"./icons/icone_download.svg"}
                            />
                        </span>
                    </td>
                </tr>
            );
        });
    };

    renderFiles = (files) => {
        return files.map((value, i) => {
            return (
                <tr key={i}>
                    <td>{value.arquivoSemExtensao}</td>
                    <td>{moment(value.data).format("L")}</td>
                    <td>
                        <svg
                            onClick={() => {
                                util.downloadFile(
                                    `/api/contrato/${this.state.contractId}/notadebito/${value.arquivoSemExtensao}`,
                                    value.arquivoSemExtensao
                                );
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
                            <path d="M0 0h24v24H0z" fill="none" />
                        </svg>
                    </td>
                </tr>
            );
        });
    };

    componentDidMount = () => {
        api.defaults.headers.common = {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("token")).accessToken
                }`
        };
        this.getContractDebits(this.state.contractId).then(() => this.setState({ loading: false }));
        // document.title = `${document.title} - Notas de Débito`
    };

    render() {
        return (
            <>
                <div className="debits-container">
                    <NotificationContainer />
                    {this.state.redirect !== "" ? (
                        <Redirect to={`${this.state.redirect}`} />
                    ) : null}
                    <Header />
                    {/* <Breadcrumbs
                        items={[
                            {
                                title: "Home",
                                link: `/home?contract=${this.state.contractId}`
                            },
                            { title: "Notas de Débitos" }
                        ]}
                    /> */}
                    {/* <ContractsPicker /> */}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                        }}
                    >
                        <Sidebar />
                        <div
                            style={{
                                width: "100%"
                            }}
                        >
                            <PageTitle
                                title='Notas de débito'
                                imgPath='./icons/sidebar/menu_notas.svg'
                            />
                            <div
                                className="page-body"
                            >
                                {!this.state.loading ?
                                    (<>
                                        <Row>
                                            <Col xs={{ size: 12 }}>
                                                {/* <Nav tabs>
                                                    <NavItem>
                                                        <NavLink
                                                            className={
                                                                this.state.activeTab === "1"
                                                                    ? "active"
                                                                    : ""
                                                            }
                                                            onClick={() => {
                                                                this.toggleTab("1");
                                                            }}
                                                        >
                                                            Notas
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                                <TabContent activeTab={this.state.activeTab}>
                                                    <TabPane tabId="1">
                                                        
                                                    </TabPane>
                                                </TabContent> */}
                                                {/* <br /> */}
                                                {this.state.debits.length > 0 ? (
                                                    <Table size="sm" striped borderless>
                                                        <thead>
                                                            <tr>
                                                                <th>Nº NOTA</th>
                                                                <th>VENCIMENTO</th>
                                                                <th>SITUAÇÃO</th>
                                                                <th>LOCAÇÃO</th>
                                                                <th>OPÇÕES</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>{this.renderData()}</tbody>
                                                    </Table>
                                                ) : (
                                                    <div className="no-items">
                                                        <strong>
                                                            Itens não disponíveis
                                                        </strong>
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                        <Modal centered isOpen={this.state.infoModal}>
                                            <ModalHeader>INFORMAÇÕES DA NOTA</ModalHeader>
                                            <ModalBody className="options-info-modal">
                                                {this.state.selectedDebit !== null ?
                                                    (<div className="info-box">
                                                        <ul>
                                                            <li>
                                                                <span className="label">
                                                                    NÚMERO DA NOTA DE DÉBITO
                                                                </span>
                                                                <br />
                                                                <span className="value">
                                                                    {this.state.selectedDebit
                                                                        .nrNotaDebito || "-"}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span className="label">
                                                                    LOCAÇÃO EM R$
                                                                </span>
                                                                <br />
                                                                <span className="value">
                                                                    R$
                                                                    {util.currenzialize(
                                                                        this.state.selectedDebit
                                                                            .vlNotaDebito
                                                                    ) || "-"}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span className="label">
                                                                    LOCAÇÃO PAGA
                                                                </span>
                                                                <br />
                                                                <span className="value">
                                                                    R$
                                                                    {util.currenzialize(
                                                                        this.state.selectedDebit
                                                                            .valorQuitado
                                                                    ) || "-"}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span className="label">TIPO</span>
                                                                <br />
                                                                <span className="value">
                                                                    {this.state.selectedDebit.tipo ||
                                                                        "-"}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    ) : ""
                                                }
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button
                                                    className="btn-hollow"
                                                    onClick={() => {
                                                        this.toggleInfos({});
                                                    }}
                                                >
                                                    CONCLUIR
                                                </Button>
                                            </ModalFooter>
                                        </Modal>
                                        <Modal centered scrollable isOpen={this.state.fileModal} style={{ overflowX: 'none' }}>
                                            <ModalHeader>Arquivos</ModalHeader>
                                            <ModalBody className="options-info-modal">
                                                <div className="boleto-disclaimer">
                                                    Os arquivos de boleto são protegidos por senha. Para abri-los, basta digitar “237” (sem as aspas), seguido dos 5 primeiros números do CNPJ do contratante.
                                                </div>
                                                {this.state.selectedDebit !== null && this.state.selectedDebit.arquivos && this.state.selectedDebit.arquivos.length ? (
                                                    <Table size="sm" striped borderless>
                                                        <thead>
                                                            <tr>
                                                                <th>ARQUIVOS</th>
                                                                <th>DATA</th>
                                                                <th>OPÇÕES</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>{this.renderFiles(this.state.selectedDebit.arquivos)}</tbody>
                                                    </Table>
                                                ) : (
                                                    <div className="no-items" style={{ textAlign: 'center' }}>
                                                        <strong>
                                                            Nenhum arquivo disponível
                                                        </strong>
                                                    </div>
                                                )}
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button
                                                    className="btn-hollow"
                                                    onClick={() => {
                                                        this.toggleFileModal(null);
                                                    }}
                                                >
                                                    FECHAR
                                                </Button>
                                            </ModalFooter>
                                        </Modal>
                                    </>
                                    )
                                    :
                                    (
                                        <Row>
                                            <Col xs={{ size: 12 }} style={{ marginTop: 40, display: 'flex', justifyContent: 'center' }}>
                                                <Spinner></Spinner>
                                            </Col>
                                        </Row>
                                    )
                                }
                                <Row>
                                    <Col md={{ size: 12 }}>
                                        <a href="/commercialvisit">
                                            <img
                                                style={{
                                                    margin: "auto",
                                                    marginTop: "55px", width: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                                src={"./images/banners/agendamento.png"}
                                            />
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
